.search-wrapper {
    position: absolute;
    top: 5vh;
    left: 0;
    right: 0;
    margin: 0 auto;

    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, 0.98);

    width: 100%;

    .search-bar-container {
        max-width: 90vh;
        margin: 1rem auto;
        input, select {
            padding: 0 0.5rem;
        }

    }

    .container {
        position: relative;
    }

    .close-btn {
        position: absolute;
        top: -3rem;
        right: 0;

        border: 1px solid #ddd;
        border-radius: 50%;

        width: 38px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

.search-results-container {

    margin: 2rem 0;
    max-height: 260px;
    overflow-y: overlay;

    ul {
        list-style: none;
        li {
            width: 100%;
            margin: 0;
            padding: 1rem 0.75rem;
            border-bottom: 1px solid #ddd;

            cursor: pointer;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}