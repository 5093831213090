html {
  box-sizing: border-box;
}
*,
*::after,
*::before {
  box-sizing: inherit;
}
* {
  margin: 0;
  padding: 0;
  transition: all 0.2s ease-in-out;
  
  font-family: 'Quicksand', sans-serif;
}
body{
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #F4F4F4;
}
a {
  color: inherit;
  text-decoration: none;
}
.H7 {
  font-size: 3pt;
  color: red;
}

option{
  background-color: rgba(157, 78, 145, 0.2);
}

.hidden {
  display: none;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix {
  zoom: 1;
} /* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
} /* IE7 */

button {
  color: #333;
}

@media (min-width: 1025px) {
 .no-scrollbar-ion-content {
    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;
  }
  .no-scrollbar-ion-content::-webkit-scrollbar {
    display: none;
  }
}
.open-new-view-button:hover {
  color: rgba(0, 0, 0, 0.5);
}
/* Forms overall */
.form-group {
  margin: 3rem 0;

  .form-control {
    border: 0;
    outline: none;
    border-bottom: 1px solid #ddd;
    margin: 0 0 1rem 0;
  }
  
}


.search-input {
  
  .form-control {
    border: 0;
    outline: none;
    border-bottom: 1px solid #ddd;
    margin: 0 0 1rem 0;
  }
  
}

.form-group-thin {
  margin: 2rem 0 1rem 0;

  .form-control {
    border: 0;
    outline: none;
    border-bottom: 1px solid #ddd;
    margin: 0 0 1rem 0;
  }
  
}

.table-body-container {
  // min-height: 400px;
  // max-height: 600px;
  overflow-y: overlay;
  overflow-x: hidden;
}

.btn-table {
  font-size: 0.9rem;
}

.table-row {
  &:hover {
    cursor: pointer;
    background-color: rgba(157, 78, 145, 0.2);
    box-shadow: 0 0 2px rgba(157, 78, 145, 0.3);
  }
}

.row{
  margin-left: 0px;
  margin-right: 0px;
    font-size: smaller;
  font-stretch: narrower;
}

.col-user-info
{
  font-size: 13pt;
}

.table-header {
  padding-left: 0px;
  padding-right: 0px;
  font-size: smaller;
}

.table-search {
  float: left;
  height: 100%;
  width: 30%;
  border: 0;
  outline: none;
  border-radius: 8px;
  padding: 0.15rem 0.5rem;

  &:active,
  &:focus {
    box-shadow: 0 0 5px rgba(#fff, 0.2);
  }
}

.float-right {
  float: right;
  margin-right: 30px ;
}