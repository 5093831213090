.login-view-form-qr-container {
  width: 100%;
  display: flex;
  flex: 1;
  height: 80%;
  align-items: center;
}

.login-google {
  flex: 0 1 100%;
  width: 100%;
  text-align: center;
}
