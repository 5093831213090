.login-view-credentials {
  width: 50%;
  flex: 1;
}

.login-view-form {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.login-form-label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: 200;
  font-size: 1.2em;
  color: rgb(75, 75, 75);
}

.login-form-input {
  width: 100%;
  height: 30px;
  min-width: 150px;
  margin: 0 auto 30px auto;
  display: block;
  padding: 0.25em 0;
  border: none;
  border-bottom: 1px solid rgba(96, 78, 151, 0.4);
  font-size: 1em;
  outline: none;
}

.login-form-input:focus,
.login-form-input:active {
  border-bottom: 1px solid #5E438C;
}

.login-form-button {
  margin: auto;
  width: 100%;
  min-width: 150px;
}